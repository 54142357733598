<template>
  <div>
    <b-modal
      :no-close-on-backdrop="true"
      ref="save-modal"
      hide-footer
      title="Edit Record"
    >
      <div
        v-if="alert"
        role="alert"
        aria-live="polite"
        aria-atomic="true"
        :class="changeClasss ? 'alert alert-success' : 'alert alert-danger'"
      >
        {{ alertText }}
      </div>
      <div class="row">
        <div class="col-md-12">
          <b-form-group
            id="input-group-2"
            label="Identifier:"
            label-for="input-2"
          >
            <b-form-input
              id="email"
              placeholder="Enter Identifier"
              v-model="identifier"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <b-form-group id="input-group-2" label="Name:" label-for="input-2">
            <b-form-input
              v-model="name"
              type="text"
              placeholder="Enter Name"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>
      <div style="float: right;">
        <a
          class="btn btn-success mr-3"
          ref="kt_login_signin_submit"
          @click="updateRecord()"
          >Update</a
        >

        <a class="btn btn-secondary" @click="hideModal()">Close</a>
      </div>
    </b-modal>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: {
    userObj: {
      type: Object,
    },
    actionType: {
      type: String,
    },
    loans: {
      type: Array,
      required: true,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modalBodyText: "",
      alert: false,
      alertText: "",
      changeClasss: false,
      identifier: "",
      name: "",
      id: "",
      tempArray: [],
    };
  },

  mounted() {
    this.identifier = this.userObj.item.identifier;
    this.name = this.userObj.item.name;
    this.id = this.userObj.item.id;
    this.userObj.item.name;
    this.$refs["save-modal"].show();
    this.showModel = true;
  },
  methods: {
    hideModal() {
      this.showModel = false;
      this.$refs["save-modal"].hide();
      this.$emit("closeModal", false);
    },

    validationMessage(message) {
      this.alert = true;
      this.alertText = message;
      this.changeClasss = false;
    },

    updateRecord() {
      try {
        if (this.identifier == "") {
          this.validationMessage("Please enter unique identifier");
          return false;
        }
        if (this.name == "") {
          this.validationMessage("Please enter name");
          return false;
        }

        this.tempArray = this.loans.filter((item) => {
          return item.id != this.userObj.item.id;
        });

        this.tempArray = this.tempArray.filter((item) => {
          return item.identifier == this.identifier;
        });

        if (this.tempArray.length > 0) {
          this.validationMessage(
            "Allergy already exist against this identifier, please enter unique identifier."
          );
          return false;
        }
        const submitButton = this.$refs["kt_login_signin_submit"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");
        const data = {
          name: this.name,
          identifier: this.identifier,
          id: this.userObj.item.id,
        };
        const headers = {
          Authorization: "Bearer " + localStorage.getItem("id_token"),
        };
        axios
          .post(this.$path + "/alergies/store", data, {
            headers,
          })
          .then((response) => {
            if (response.data.code == 200) {
              this.$refs["save-modal"].hide();
              this.$emit("updateRecord", data);
            }
          });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },

    commonAction() {
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      if (this.actionType == "delete") {
        this.deleteRecord();
      }
      if (this.actionType == "delete-resturant-image") {
        this.deleteRestaurantImage();
      }
      if (this.actionType == "delete-menu-item") {
        this.deleteMeunItem();
      }
      if (this.actionType == "active" || this.actionType == "deactive") {
        this.changeStatus();
      }
    },
  },
};
</script>
<style scoped>
.btn-success {
  border-color: #5cb700;
  background-color: rgb(92, 183, 0);
}
.btn.btn-success:hover {
  border-color: #5cb700 !important;
  background-color: rgb(92, 183, 0) !important;
}
</style>
