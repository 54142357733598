<template>
  <div class="card">
    <div class="card-body">
      <div class="col-12 mt-2">
        <template v-if="isShowModel">
          <VerficationModal
            :userObj="userObj"
            :actionType="actionType"
            @changeStatus="singlechangeStatus"
            @deleteRow="deleteRow"
            @closeModal="closeModal"
          />
        </template>

        <template v-if="isShowEditModel">
          <EditModal
            :loans="loans"
            @closeModal="closeModal"
            :userObj="userObj"
            @updateRecord="updateRecord"
          />
        </template>
        <div
          v-if="alert"
          role="alert"
          aria-live="polite"
          aria-atomic="true"
          :class="changeClasss ? 'alert alert-success' : 'alert alert-danger'"
        >
          {{ alertText }}
        </div>
        <div>
          <button
            style="float: right; margin-left: 12px;margin-right: 12px;margin-top: 122px"
            class="btn btn-danger btn btn-danger rounded-pill mb-2"
            data-toggle="modal"
            data-target="#modalId"
            @click="confirmationBulkAction('delete')"
          >
            Delete
          </button>

          <button
            style="float: right;margin-left: 12px;margin-top: 122px"
            class="btn btn-primary btn btn-primary rounded-pill mb-2"
            @click="confirmationBulkAction('deactive')"
          >
            Inactive
          </button>

          <button
            style="float: right;margin-left: 12px;margin-top: 122px;"
            class="btn btn-info btn btn-info rounded-pill mb-2"
            id="add-loan-btn"
            @click="confirmationBulkAction('active')"
          >
            Active
          </button>
        </div>
        <h4 class="card-title mb-5">Allergies</h4>
        <b-modal ref="my-modal" hide-footer title="Confirmation">
          <div class="d-block">
            <h4>{{ this.modalBodyText }}</h4>
          </div>
          <div style="float: right;">
            <button
              type="button"
              ref="kt_login_signin_submit"
              class="btn btn-danger mr-3"
              @click="changeStatus()"
            >
              Yes
            </button>
            <button type="button" class="btn btn-secondary" @click="hideModal">
              No
            </button>
          </div>
        </b-modal>
        <div
          style="display:flex;justify-content:space-between;align-items:center;"
        >
          <div style="width:50%;margin-left:10px">
            <b-form-group
              id="input-group-2"
              label="Identifier:"
              label-for="input-2"
            >
              <b-form-input
                v-model="identifier"
                type="text"
                placeholder="Enter Identifier"
              ></b-form-input>
            </b-form-group>
          </div>
          <div style="width:50%;margin-left:10px">
            <b-form-group id="input-group-2" label="Name:" label-for="input-2">
              <b-form-input
                v-model="name"
                type="text"
                placeholder="Enter Name"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
        <div style="display:flex;align-items:center;">
          <div style="width:10%;padding-left:10px">
            <a class="btn btn-success" @click="searcResultApi()">Save</a>
          </div>
        </div>
        <hr />
        <div
          v-if="loading"
          class="d-flex justify-content-center align-items-center"
          style="height:100vh"
        >
          <img
            :src="'./images/loader.gif'"
            class="mr-2"
            style="width:100px;height:100px"
          />
        </div>

        <div class="loading" v-if="!loading">
          <div class="row mb-md-2">
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Show
                  <b-form-select
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                  ></b-form-select>
                  entries
                </label>
              </div>
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6">
              <div
                id="tickets-table_filter"
                class="dataTables_filter text-md-right"
              >
                <label class="d-inline-flex align-items-center">
                  Search:
                  <b-form-input
                    v-model="filter"
                    type="search"
                    placeholder="Search..."
                    class="form-control form-control-sm ml-2"
                  ></b-form-input>
                </label>
              </div>
            </div>
          </div>
          <div>
            <div class="table-responsive mb-0">
              <b-table
                id="facture12"
                :items="loans"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                ref="selectableTable"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                show-empty
                empty-text="No record found"
                empty-filtered-text="No search result found"
              >
                <template v-slot:cell(status)="loans">
                  <i
                    v-if="loans.item.status == '1'"
                    style="color:green;cursor: pointer;"
                    class="fas fa-check"
                    @click="singlActiveDeactive(loans)"
                  ></i
                  >&nbsp;
                  <i
                    v-if="loans.item.status == '0'"
                    style="color:red;cursor: pointer;"
                    class="fas fa-times"
                    @click="singlActiveDeactive(loans)"
                  ></i>
                </template>

                <template v-slot:cell(actions)="loans">
                  <i
                    class="fas fa-edit"
                    style="color:green;cursor: pointer;"
                    @click="editData(loans)"
                  ></i
                  >&nbsp;
                  <i
                    style="color:red;cursor: pointer;"
                    class="fas fa-trash-alt"
                    @click="deleterRecord(loans)"
                  ></i>
                </template>

                <template #head(id)>
                  <input v-model="isSelectAll" type="checkbox" />
                </template>
                <template v-slot:cell(id)="loans">
                  <input
                    type="checkbox"
                    :value="loans.item.id"
                    v-model="checkedNames"
                  />
                </template>
              </b-table>
            </div>
          </div>
        </div>
        <br /><br />
        <div class="row">
          <div class="col">
            <div class="dataTables_paginate paging_simple_numbers float-right">
              <ul class="pagination pagination-rounded mb-0">
                <!-- pagination -->
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                ></b-pagination>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script src="https://cdnjs.cloudflare.com/ajax/libs/jspdf/0.9.0rc1/jspdf.min.js"></script>
<script>
import store from "@/core/services/store/index.js";

import axios from "axios";
import Multiselect from "vue-multiselect";
// import { jsPDF } from "jspdf";
import VerficationModal from "./VerficationModal";
import EditModal from "./EditModal";
import jsPDF from "jspdf";
// import html2canvas from "html2canvas";
// import html2pdf from "html2pdf.js";
// import autoTable from "jspdf-autotable";
// import domtoimage from "dom-to-image";
import $ from "jquery";

export default {
  components: {
    Multiselect,
    VerficationModal,
    EditModal,
  },
  data() {
    return {
      checkedNames: [],
      isShowModel: false,
      isShowEditModel: false,
      showRestaurantUserDiv: false,
      changeClasss: false,
      alert: false,
      fees: 0,
      actionType: "",
      userObj: {},
      identifier: "",
      isSelectAll: false,
      name: "",
      id: "",
      totalPriceOfOrders: 0,
      onLineOrderAmount: 0,
      alertText: "",
      modalBodyText: "",
      restaurantuser: "",
      unSelectedCheckbox: [],
      loans: [],
      tempArray: [],
      newArray: [],
      loading: false,
      startDate: "",
      startPage: 0,
      endPage: 0,
      endDate: "",
      sortDesc: true,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      fields: [
        { key: "id", label: "Select" },
        { key: "identifier", label: "Identifier" },
        { key: "name", label: "Name" },
        { key: "status", label: "Status" },
        { key: "actions", sortable: true },
      ],
    };
  },

  mounted() {
    document.title = "Allergies";
    if (
      store.state.loginUserType == "superadmin" ||
      store.state.loginUserType == "admin"
    ) {
      this.showRestaurantUserDiv = true;
    }
    this.getRecord();
  },

  computed: {
    rows() {
      return this.loans.length;
    },
  },

  methods: {
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    singlechangeStatus(getUser) {
      this.changeClasss = true;
      let index = this.loans.findIndex((x) => x.id === getUser.id);
      if (index !== -1) {
        this.loans[index].status = getUser.status;
      }
      this.alert = true;
      if (this.actionType == "active") {
        this.alertText = "Record deactivated successfully";
      }
      if (this.actionType == "deactive") {
        this.alertText = "Record activated successfully";
      }
      this.hideAlert();
    },

    addClassToButton() {
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
    },

    changeStatus() {
      try {
        this.addClassToButton();
        this.showDeleteModal = true;
        const data = { status: this.status, bluckid: this.checkedNames };
        const headers = {
          Authorization: "Bearer " + localStorage.getItem("id_token"),
        };
        axios
          .post(this.$path + "/alergies/bulkAction", data, {
            headers,
          })
          .then((response) => {
            if (response.data.code == 200) {
              this.$refs["my-modal"].hide();
              this.getRecord();
              this.alert = true;
              if (this.status == "delete") {
                this.changeClasss = false;
                this.alertText = "Record deleted successfully";
              }
              if (this.status == "active") {
                this.changeClasss = true;
                this.alertText = "Record activated successfully";
              }
              if (this.status == "deactive") {
                this.changeClasss = true;
                this.alertText = "Record deactivated successfully";
              }
              this.checkedNames = [];
              this.hideAlert();
              this.isSelectAll = false;
            }
          });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },

    confirmationBulkAction(status) {
      if (this.checkedNames.length > 0) {
        if (status == "delete") {
          this.status = "delete";
          this.modalBodyText = "Do You Want To Delete This Record?";
        }
        if (status == "active") {
          this.status = "active";
          this.modalBodyText = "Do You Want To Active This Record?";
        }
        if (status == "deactive") {
          this.status = "deactive";
          this.modalBodyText = "Do You Want To Deactive This Record?";
        }
        this.$refs["my-modal"].show();
      } else {
        this.alert = true;
        this.alertText = "Please Select checkbox";
        this.hideAlert();
      }
    },

    updateRecord(getUser) {
      let index = this.loans.findIndex((x) => x.id === getUser.id);
      if (index !== -1) {
        this.loans[index].identifier = getUser.identifier;
        this.loans[index].name = getUser.name;
      }
      this.isShowEditModel = false;
      this.changeClasss = true;
      this.alert = true;
      this.alertText = "Record has been updated successfully";
      this.hideAlert();
    },

    deleteRow(getUser) {
      this.actionType == "delete";
      let index = this.loans.findIndex((x) => x.id === getUser.item.id);
      if (index !== -1) {
        this.loans.splice(index, 1);
      }

      this.alert = true;
      this.alertText = "Record deleted successfully";
      this.hideAlert();
    },

    closeModal(value) {
      this.isShowModel = value;
      this.isShowEditModel = value;
    },

    hideAlert() {
      setTimeout(() => (this.alert = false), 2000);
    },

    emptyFiled() {
      (this.name = ""), (this.id = ""), (this.identifier = "");
    },

    searcResultApi() {
      if (this.identifier == "") {
        this.validationMessage("Please enter unique identifier");
        return false;
      }
      if (this.name == "") {
        this.validationMessage("Please enter name");
        return false;
      }

      this.tempArray = this.loans.filter((item) => {
        return item.identifier == this.identifier;
      });

      if (this.tempArray.length > 0) {
        this.validationMessage(
          "Allergy already exist against this identifier, please enter unique identifier."
        );
        return false;
      }

      const headers = {
        Authorization: "Bearer " + localStorage.getItem("id_token"),
      };
      const data = {
        name: this.name,
        identifier: this.identifier,
      };
      try {
        axios
          .post(this.$path + "/alergies/store", data, {
            headers,
          })
          .then((response) => {
            this.getRecord();
            this.emptyFiled();
            this.changeClasss = true;
            this.alert = true;
            this.alertText = "Record has been added successfully";
            this.hideAlert();
          });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },

    validationMessage(message) {
      this.alert = true;
      this.alertText = message;
      this.changeClasss = false;
    },

    getRecord() {
      this.loading = true;
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("id_token"),
      };

      try {
        axios
          .post(this.$path + "/alergies/list", "", {
            headers,
          })
          .then((response) => {
            this.loans = response.data.data;
            this.loading = false;
          });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },

    customLabel({ name, location }) {
      return `${name}—(${location})`;
    },
    restaurantUserLable({ username, email }) {
      return `${username}—(${email})`;
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    singlActiveDeactive(object) {
      this.userObj = object;
      if (object.item.status == "1") {
        this.actionType = "active";
      }
      if (object.item.status == "0") {
        this.actionType = "deactive";
      }
      this.isShowModel = true;
    },

    deleterRecord(object) {
      this.userObj = object;
      this.actionType = "delete";
      this.isShowModel = true;
      // this.$emit("deleteData", object);
    },

    editData(object) {
      this.userObj = object;
      this.isShowEditModel = true;
    },

    orderDetail(object) {
      this.$emit("orderDetail", object);
    },

    changeClass(loans) {
      if (loans.item.status == 1) {
        return "btn btn-info btn-sm";
      } else {
        return "btn btn-primary btn-sm";
      }
    },
    changeVerfiedtext(loans) {
      if (loans.item.status == 1) {
        return "Active";
      } else {
        return "Deactive";
      }
    },
  },

  watch: {
    isSelectAll: function(val) {
      if (val) {
        this.checkedNames = this.$refs.selectableTable.paginatedItems.map(
          (x) => x.id
        );
      } else {
        this.checkedNames = [];
      }
    },
  },
};
</script>

<style scoped>
.btn-success {
  border-color: #5cb700;
  background-color: rgb(92, 183, 0);
}
.btn.btn-success:hover {
  border-color: #5cb700 !important;
  background-color: rgb(92, 183, 0) !important;
}
.btn-info {
  background-color: #697165;
  border-color: #697165;
}
.btn-info:hover {
  border-color: #697165 !important;
  background-color: #697165 !important;
}
.btn-primary {
  border-color: #949b8f !important;
  background-color: #949b8f !important;
}
.btn-primary:hover {
  border-color: #949b8f !important;
  background-color: #949b8f !important;
}
.btn-primary:active {
  border-color: #949b8f !important;
  background-color: #949b8f !important;
}

.btn-info:active {
  border-color: #697165 !important;
  background-color: #697165 !important;
}

.btn-primary:focus {
  border-color: #949b8f !important;
  background-color: #949b8f !important;
}

.btn-info:focus {
  border-color: #697165 !important;
  background-color: #697165 !important;
}
</style>
